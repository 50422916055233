import Layout from '../Layout.vue'
import Promotions from '../components/promotions/Promotion.vue'

const routes = [
  {
    path: '/',
    component: Layout,
    props: { button: 'menu' },
    children: [
      { path: '/promotions', name: 'promotions', component: Promotions }
    ]
  }
]

export default routes
