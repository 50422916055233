<template lang="pug">
  ErrorLayout
    div.centered
      h2(v-if="status > 0") {{status}}
      h4 We're sorry, there was an error processing that request...
      div(v-if="!!description")
        pre {{description}}
      div.mt-4(v-if="!!correlationId").body-1 reference: {{correlationId}}
      h6.mt-3 Return 
        a(@click="goHome") Home
</template>

<script>
import ErrorLayout from './ErrorLayout.vue'

export default {
  props: {
    status: {
      type: Number,
      required: false
    },
    message: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    correlationId: {
      type: String,
      required: false
    }
  },
  components: {
    ErrorLayout
  },
  methods: {
    goHome () {
      return this.$router.push('/')
    }
  }
}
</script>

<style lang="sass" scoped>
.centered 
  font-size: 1.7rem
  text-align: center

pre
  font-size: 1rem
  white-space: pre-wrap
  text-align: left
  margin: 5rem
</style>
