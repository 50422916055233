const handleFiveHundreds = (router, err) => {
  const status = err.status || 0
  const message = err.message || err.statusText || 'Server error'
  const description = err.stack || ''
  const correlationId = (err.headers || {})['trv-correlation-id'] || ''

  return router.push({
    name: 'serverError',
    params: { status, message, description, correlationId }
  })
}

const handleUnauthorized = router => {
  return router.push('/')
}

const handleNotFound = router => {
  return router.push({ name: 'pageNotFound' })
}

const handleServerError = router => axiosData => {
  if (!axiosData.response) {
    console.log(axiosData)
  }
  const err = axiosData.response || { ...axiosData, status: 509 }

  const status = +err.status || 0
  if (Math.floor(status / 100) === 5) {
    handleFiveHundreds(router, err)
  } else if (status === 401) {
    handleUnauthorized(router, err)
  } else if (status === 404) {
    handleNotFound(router, err)
  } else {
    const status = err.status || 0
    const message = err.message || ''
    const description = err.stack || ''
    return router.push({
      name: 'serverError',
      params: { status, message, description }
    })
  }
}

export default handleServerError
