<template lang="pug">
    div(class="tds-card tds-card--medium tds-card--level-5")
      div(class="tds-card__body")
        p.list-heading How to use your code on Amazon.com
        ol.big-number-bullets
          li Add your desired item to your Amazon.com shopping cart.
          li At checkout, enter your promo code in the &ldquo;Add a gift card or promotion code&rdquo; field within the payment section and click &ldquo;Apply&rdquo;. Your order will be updated to reflect the discounted price.
          li Place your order.
        small(v-if="showSmart") Note: If you choose to add installation services, Amazon Home Services will email you follow up instructions to schedule the installation.
</template>

<script>
export default {
  props: {
    showSmart: {
      type: Boolean,
      default: false
    }
  }
}
</script>
