<template lang="pug">
  small
    a(@click.prevent="copyToClipboard") (copy code to clipboard)
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default {
  props: {
    promoCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    copyToClipboard(e) {
      const tempForCopy = 'temp_for_copy'
      const copyButton = e.target

      const textarea = document.createElement('textarea')
      textarea.id = tempForCopy
      textarea.style.position = 'fixed'
      textarea.style.top = 0
      textarea.style.left = 0
      textarea.style.height = 0
      document.body.appendChild(textarea)
      textarea.value = this.promoCode
      const selector = document.getElementById(tempForCopy)
      selector.select()

      // added for iOS issues
      // see https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
      // and https://gist.github.com/rproenca/64781c6a1329b48a455b645d361a9aa3
      const range = document.createRange()
      range.selectNodeContents(textarea)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textarea.setSelectionRange(0, 999999)

      document.execCommand('copy')
      document.body.removeChild(textarea)

      const tooltipOptions = {
        content: 'Promo code copied to clipboard',
        trigger: 'manual',
        duration: 500,
        animation: 'fade',
        animateFill: false,
        interactive: false,
        theme: `tds-tooltip`,
        delay: [0, 3000],
        distance: -100
      }
      const tip = copyButton._tippy || tippy.one(copyButton, tooltipOptions)
      this.$nextTick(() => {
        tip.show()
        setTimeout(() => tip.hide(), 2000)
      })
    }
  }
}
</script>
