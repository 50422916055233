<template lang="pug">
  svg(aria-hidden="true"
    :class="getIconClass"
    focusable="false"
    ref="icon"
    role="img"
    :style="styleObject")
    use(:xlink:href="iconPath" aria-hidden="true" focusable="false")
</template>
<script>
const baseIconPath = '/assets/svg/symbol-sprites.svg#'
const logoIconPath = '/assets/logos/svg/symbol-sprites.svg#'
export default {
  props: {
    iconType :{
      type: String,
      default : 'base'
    },
    iconClass :{
      type: String,
      default :'tds-button__icon'
    },
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      styleObject: {
        color: this.color,
        width: this.size + 'px',
        height: this.size + 'px'
      }
    }
  },
  computed: {
    iconPath() {
      if(this.iconType === 'logo') {
        return logoIconPath + this.name
      }
      return baseIconPath + this.name      
    }, 
    getIconClass () {
      return this.iconClass
    }
  }
}
</script>
