<template lang="pug">
  footer.tds-global-footer(role="contentinfo")
    .tds-global-footer__wrapper
      .tds-global-footer__identity
        svg.tds-global-footer__logo(role='img', focusable='false', aria-label='Travelers logo')
          use(href='/assets/svg/symbol-sprites.svg#trv-logo-2color-small')
        p.tds-global-footer__copy © {{ currentYear }} The Travelers Indemnity Company. All rights reserved.
      ul.tds-global-footer__social
        li
          a.tds-global-footer__social-item(href='https://www.facebook.com/travelers', title='Facebook' target="_blank")
            span.tds-screen-readers-only Travelers on Facebook
            svg.fb
              use(href='/assets/svg/symbol-sprites.svg#social-fb-1-18')
        li
          a.tds-global-footer__social-item(href='https://www.youtube.com/user/TravelersInsurance', title='YouTube' target="_blank")
            span.tds-screen-readers-only Travelers on YouTube
            svg.yt
              use(href='/assets/svg/symbol-sprites.svg#social-yt-1-18')
        li
          a.tds-global-footer__social-item(href='https://twitter.com/travelers', title='Twitter' target="_blank")
            span.tds-screen-readers-only Travelers on Twitter
            svg.tw
              use(href='/assets/svg/symbol-sprites.svg#social-twitter-1-18')
        li
          a.tds-global-footer__social-item(href='http://us.linkedin.com/company/travelers', title='LinkedIn' target="_blank")
            span.tds-screen-readers-only Travelers on LinkedIn
            svg.li
              use(href='/assets/svg/symbol-sprites.svg#social-linkedin-1-18')
      .tds-global-footer__disclaimer
        small
          p
            | Home insurance is underwritten by The Travelers Indemnity Company and its property and casualty affiliates, One Tower Square, Hartford, CT 06183. In TX: home insurance is underwritten by Travelers Personal Insurance Company, One Tower Square, Hartford, CT 06183.
          p
            a#aPrivacyCenter(target='_blank', observe='privacy_center', href='https://www.travelers.com/w3c/legal/privacypolicy.html') Privacy &amp; Security
            |  |
            a#aLegalNotice(target='_blank', observe='legal_notice', href='https://www.travelers.com/w3c/legal/') Terms of Service

</template>
<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>
