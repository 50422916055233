<template lang="pug">
  div(:class="alertClass")
    p.tds-app-alert__title {{title}}
    p.alert-content
      slot
    button(v-if="dismissible" aria-label="Dismiss alert." class="tds-alert__dismiss" data-focus="#my-alert-info-dismissible-trigger")
      svg(aria-hidden="true" focusable="false")
        use(xlink:href="/assets/svg/circle-question-mark-24px.svg")
</template>

<script>
import Button from './Button'
import Icon from './Icon'

export default {
  components: {
    Button,
    Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    level: {
      type: String,
      validator: str =>
        ['error', 'info', 'warning', 'success'].indexOf(str) !== -1,
      required: true
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    alertClass() {
      const classes = ['tds-app-alert--' + this.level]
      if (this.dismissible) {
        classes.push('tds-alert--dismissible')
      }
      return classes.join(' ')
    }
  }
}
</script>

<style scoped lang="scss">
.alert-content {
  font-size: 0.9rem;
}

.tds-app-alert--success::before {
  background-image: url("/assets/svg/circle-question-mark-24px.svg")
}
</style>
