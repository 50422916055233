<template lang="pug">
  div(class="tds-container")
   div(class="tds-card tds-card--large tds-card--level-5")
    div(class="tds-card__body" style="align:center;")
     div(class="tds-card__body-title tds-h2" ) 
        span(style="color:#e01719") Welcome to Travelers Additional Benefits 
     p(class="tds-small") There are currently no offers or promotions available. As a Travelers Home Insurance customer, you may be eligible for future offers and promotions. 
     p(class="tds-small") Please check this #[a( href='https://www.travelers.com/personal-insurance/additionalbenefits' ) site] periodically to see which offers may be added. 
</template>

<script>
import { mapActions } from "vuex";
import MaskedTextInput from '../components/tds/MaskedTextInput'
import TextInput from '../components/tds/TextInput'
import Button from '../components/tds/Button'
import Alert from '../components/tds/Alert'
import VerifyError from '../components/VerifyError'
import Spinner from './tds/Spinner'

export default {
  components: {
    MaskedTextInput,
    TextInput,
    Button,
    Alert,
    VerifyError,
    Spinner
  },
  data: () => ({
    verifyLoading: false,
    isDown: false,
    policyNumber: '',
    zipCode: '',
    email: '',
    errorStatus: 0,
    validationError: '',
    customer: 'Amazon',
    loading: false,
    state: {
      READY: 100,
      TOKEN_LOADING: 200
    },
    currentState: 100
  }),
  computed: {
    alertText() {
      return this.validationError
    }
  },
  mounted() {
    this.currentState = this.state.READY
    if (this.$route.query && this.$route.query.token) {
      this.currentState = this.state.TOKEN_LOADING
      const token = decodeURIComponent(this.$route.query.token).replace(/ /g, '+')
      return this.$api.decryptToken(token)
        .then(policyInfo => {
          if(!policyInfo.policyNumber || !policyInfo.zipCode ) {
             this.currentState = this.state.READY
             return this.$router.push('/')
          }
          return this.verify(policyInfo.policyNumber, policyInfo.zipCode)
        })
        .catch(err => {
          this.currentState = this.state.READY
        })
    }
  },
  watch: {
    policyNumber() {
      this.inputChange()
    },
    zipCode() {
      this.inputChange()
    }
  },
  methods: {
    ...mapActions('auth', ['verifyPolicy']),
    submit() {
      this.loading = true
      if (!this.validate()) {
        this.loading = false
        return        
      }
      return this.verify(this.policyNumber, this.zipCode)
    },
    verify(policyNumber, zipCode) {
      return this.verifyPolicy({ policyNumber, zipCode })
        .then(() => {
          this.loading = false
          return this.$router.push('/promotions')
        })
        .catch(error => {
          this.errorStatus = (error.response && error.response.status) || 500
          this.loading = false
        })
    },
    inputChange() {
      this.validationError = ''
    },
    validate() {
      if (!this.policyNumber) {
        this.validationError = 'Policy Number is required'
        return false
      }
      if (this.policyNumber.length < 15) {
        this.validationError = 'Policy Number is not valid'
        return false
      }
      if (!this.zipCode) {
        this.validationError = 'Zip Code is required'
        return false
      }
      if (this.zipCode.length < 5) {
        this.validationError = 'Zip Code is not valid'
        return false
      }
      return true
    },
  }
}
</script>