<template lang="pug">
   div(class="tds-card tds-card--medium tds-card--level-5")
     div(class="tds-card__body")
        h2(class="tds-h2--article") FAQ
        
        h4 Do I need an Amazon.com account to redeem these promotions?
        p Yes. You can easily 
          a(href='https://www.amazon.com/ap/register?_encoding=UTF8&openid.assoc_handle=usflex&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.mode=checkid_setup&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.ns.pape=http%3A%2F%2Fspecs.openid.net%2Fextensions%2Fpape%2F1.0&openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fwww.amazon.com%2Fgp%2Fyourstore%2Fhome%3Fie%3DUTF8%26ref_%3Dnav_newcust&ref=sxts_snpl_2_1_30ad57ad-4536-418c-b6ee-a2e16191caab&qid=1537900531&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=30ad57ad-4536-418c-b6ee-a2e16191caab&pf_rd_r=TEK583QTMMAE84ZJE499&pd_rd_wg=O096Q&pf_rd_s=desktop-signpost&pf_rd_t=301&pd_rd_w=MtNTx&pf_rd_i=create+account&pd_rd_r=f25f0283-06f2-43f3-a80f-5865876dccce' target='_blank') create an Amazon.com account here (opens in new window)
          | .
        
        div(v-if="showSmart")
          h4 How do I add a discount for having Smart Home Devices?
          p Once you&rsquo;ve installed the Smart Home Kit, call your independent agent or Travelers at 800.842.5075 and we&rsquo;ll be happy to help.

        div(v-if="showSmart")
          h4 How does installation work through Amazon Home Services?
          p In select areas, you will be eligible to add an optional installation service for an additional fee. Amazon Home Services will schedule an appointment to come to your house and install all the devices included in your Smart Home Kit. This will include integrating the devices with your Echo Dot and mobile devices. For more information, 
            a(href='https://www.amazon.com/Home-Services-FAQs/b?ie=UTF8&node=13409637011' target='_blank') see the FAQ on Amazon Home Services page (opens in new window)
            | .
</template>

<script>
export default {
  props: {
    showSmart: {
      type: Boolean,
      default: false
    }
  }
}
</script>
