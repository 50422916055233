<template lang="pug">
  svg(:class="spinnerClass"
    focusable="false"
    ref="icon"
    :style="styleObject")
    use(:xlink:href="iconPath")
</template>
<script>
const baseIconPath = '/assets/svg/symbol-sprites.svg#'
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: null
    },
    spinnerClass : {
      type: String,
      default: 'tds-icon-24 tds-spin'
    },
  },
  data() {
    return {
      styleObject: {
        color: this.color,
        width: this.size + 'px',
        height: this.size + 'px'
      }
    }
  },
  computed: {
    iconPath() {
      return baseIconPath + this.name
    }
  }
}
</script>

<style lang="scss" scoped>

.tds-icon-24 {
    height: 1.5rem;
    width: 1.5rem;
}
</style>