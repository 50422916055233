<template lang="pug">
  header.tds-global-header(role="banner")
    div(class="tds-global-header__wrapper")
      div(class="tds-global-header__identity-block")
        a(class="tds-global-header__identity" href="/" rel="home")
           Icon(name="trv-logo-2color-small" iconType="logo" iconClass="tds-global-header__logo")
           div(class="tds-global-header__product") Travelers Additional Benefits
</template>

<script>
import Icon from './components/tds/Icon'
import svg4everybody from 'svg4everybody'

export default {
  components: {
    Icon
  },
  mounted() {
    svg4everybody()
  }
}
</script>