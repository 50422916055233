<template lang="pug">
  div(style="outline:none" tabindex="-1" id="gatsby-focus-wrapper")
    div.tds-app-wrapper
      Header
      div.tds-body
        div.tds-container
          div(class="tds-row secondary-page-module--page--EF-Fy")
            router-view
      Footer
</template>
<script>
import Icon from './components/tds/Icon'
import svg4everybody from 'svg4everybody'
import Footer from './Footer'
import Header from './Header'

export default {
  components: {
    Icon,
    Footer,
    Header
  },
  computed: {
    page() {
      return this.$route.name
    }
  },
  mounted() {
    svg4everybody()
  } 
}
</script>


<style lang="scss">

.secondary-page-module--page--EF-Fy {
  padding-top: 2em;
}

.tds-card--level-5, .tds-card--level-6 {
    border-radius: .375rem;
    background-color: #fff;
    list-style: none;
}

.tds-card--level-5 {
    box-shadow: 0 0.0625rem 0.125rem 0 rgba(51,93,121,.07), 0 0.125rem 0.25rem 0 rgba(51,93,121,.07), 0 0.25rem 0.5rem 0 rgba(51,93,121,.07), 0 0.5rem 1rem 0 rgba(51,93,121,.07), 0 1rem 2rem 0 rgba(51,93,121,.07);
}

.tds-card--small {
    width: 100%;
    max-width: 19.0625rem;
}
.tds-card {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: nowrap;
}
.tds-card__body {
    order: 1;
    padding: 1.125rem;
    flex: 1 0 auto;
}

.tds-card--level-5, .tds-card--level-6 {
    border-radius: .375rem;
    background-color: #fff;
    list-style: none;
}

.tds-card__body:last-child {
    margin-bottom: 0!important;
}
</style>
