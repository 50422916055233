<template lang="pug">
  ErrorLayout
    div.centered
      h2 404
      h4 We're sorry, we couldn't find what you're looking for...
      h6.mt-3 Return 
        a(@click="goHome") Home
</template>

<script>
import ErrorLayout from './ErrorLayout.vue'

export default {
  components: {
    ErrorLayout
  },
  methods: {
    goHome () {
      return this.$router.push('/')
    }
  }
}
</script>

<style lang="sass" scoped>
.centered
  font-size: 1.7rem
  text-align: center
</style>
