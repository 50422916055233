<template lang="pug">
  div
    Alert(level="error" v-if="localServerStatus >= 400 && localServerStatus < 500")
      p We are unable to verify your eligibility.  Please verify the information and try again.
      p If you believe you meet the eligibility outlined 
        a(href="http://www.travelers.com/additionalbenefits" target="_blank") here
        | , please email our support team at 
        a(:href="supportEmailLink") {{supportEmail}}
        | .  Be sure to include your name and policy number in the email.
    Alert(level="error" v-else-if="localServerStatus > 0")
      p Unable to verify your eligibility at this time.  Please try again later.
</template>

<script>
import Alert from './tds/Alert'
import { mapGetters } from 'vuex'
import { path } from 'ramda'

export default {
  components: {
    Alert
  },
  props: {
    localServerStatus: {
      type: Number,
      default: 0
    },
    policyNumber: {
      type: String,
      default: ''
    },
    zipCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      supportEmail: 'PIPromoSupport@travelers.com'
    }
  },
  computed: {
    ...mapGetters('policy', ['policy']),
    supportEmailLink() {
      const policyNumber = this.policy.policyNumber ? this.policy.policyNumber : 'N/A'
      const zipCode = path(['customer', 'address', 'zipCode'], this.policy) ? path(['customer', 'address', 'zipCode'], this.policy).substring(0, 5) : 'N/A'
      const body = encodeURIComponent(
        `Travelers Promotion Support Team,
        Please verify my eligibility for any promotions.
        
        Pol #:  ${policyNumber}
        Zip Code: ${zipCode}`
      )
      const subject = encodeURIComponent(
        `Additional Benefits Policy Eligibility`
      )
      return `mailto:${this.supportEmail}?subject=${subject}&body=${body}`
    }
  },
  watch: {},
  methods: {}
}
</script>
