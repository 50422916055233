<template lang="pug">
  div.tds-field
    label(class="tds-field__label" :for="id" ) {{label}}
    input(
      :type="type"
      class="tds-field__input"
      @input="$emit('input', $event.target.value)" 
      :value="value"
      :placeholder="placeholder"
      :required="required"
      data-enhanced="true"
      :id="id"
      :readonly="readOnly"
      :aria-required="required"
      :maxLength="maxLength > 0 && maxLength")
</template>
<script>
export default {
  props: {
    for: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: -1
    },
    placeholder: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      validator: str =>
        ['tel', 'email', 'search', 'password', 'url', 'text'].indexOf(str) !==
        -1,
      default: 'text'
    }
  }
}
</script>
