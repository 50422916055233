<template lang="pug">
  div(class="tds-container")
    div(class="tds-card tds-card--medium tds-card--level-5")
      div(class="tds-card__body" style="align:center;")
        h2(class="tds-h2--article") $25 off one of Three Smart Home Device Kits
        img.partner-logo(:src='imageMap.amazonlogo' alt='Amazon logo')
        div(class="tds-row tds-mt-m")
          div(class="tds-col-xl-3 tds-col-lg-4 tds-col-md-6" style="margin-bottom: 1rem;")
            div(class="tds-card tds-card--medium tds-card--level-5")
              a(:href="codeLinks.guard.url" target="_blank")
                img.responsive-offer-imgs(:src="imageMap['smarthomeguard']"
                   alt='Get $25 off Alexa Guard Smart Home Kit. Get your code below.')
              div(class="tds-card__body")
                h3(class="tds-card__body-header tds-h3") Alexa Guard Kit  
                p(class="tds-small")
                  | The Echo Show 5 brings the power of Alexa to you in a smart display. It integrates with the Ring Indoor Camera.  This camera uses motion detection and instantly alerts you via the Ring app of unexpected events in your home, and allows you to monitor your camera in real time. The Amazon Smart Plug can turn any lamp into a smart light and works well with Amazon’s 
                  a(:href="alexaGuardOfficialLink" target="_blank") Alexa Guard
                  |  feature: Alexa can send you Smart Alerts, via phone notifications, if your Echo device detects the sound of smoke alarms, carbon monoxide alarms, or glass breaking.  Alexa Guard can also control your smart lights and plugs when armed to make it look like somebody is home while you are away.
                a(:href='codeLinks.guard.url' class="tds-small" target='_blank') View  
                  strong Alexa Guard Kit 
                  | On Amazon.com 
                  small (opens in new window)
                ul(class="tds-small")
                  li Amazon Alexa Show 5
                  li Ring Indoor Camera
                  li Amazon Smart Plug
          div(class="tds-col-xl-3 tds-col-lg-4 tds-col-md-6" style="margin-bottom: 1rem;")      
            div(class="tds-card tds-card--medium tds-card--level-5")
              a(:href="codeLinks.camera.url" target="_blank")
                img.responsive-offer-imgs(:src="imageMap['smarthomecamera']"
                 alt='Get $25 off Camera Smart Home Kit. Get your code below.')
              div(class="tds-card__body")
                h3(class="tds-card__body-header tds-h3") 2 Camera Kit  
                p(class="tds-small") The Blink Indoor Camera and Blink XT2 Indoor/Outdoor Camera use motion detection and can instantly alert you via the Blink app of unexpected events in and around your home, and allows you to monitor your cameras in real time. This kit gives you flexibility to monitor your home, both inside and outside, while you are away.
                a(:href='codeLinks.camera.url' class="tds-small" target='_blank') View 
                 strong 2 Camera Kit 
                  | On Amazon.com 
                 small (opens in new window)
                ul(class="tds-small")
                 li Blink Indoor Camera
                 li Blink XT2 Indoor/Outdoor Camera
          div(class="tds-col-xl-3 tds-col-lg-4 tds-col-md-6" style="margin-bottom: 1rem;")          
            div(class="tds-card tds-card--medium tds-card--level-5")
              a(:href="codeLinks.water.url" target="_blank")
                img.responsive-offer-imgs(:src="imageMap['smarthomewater']"
                alt='Get $25 off Water Smart Home Kit. Get your code below.')
              div(class="tds-card__body")
                h3(class="tds-card__body-header tds-h3") Water Leak Kit                
                p(class="tds-small") The SmartThings Hub and Water Sensors are designed to help you catch water leaks early and reduce the extent of damage that can be caused by leaks. The Blink Indoor Camera uses motion detection and can instantly alert you via the Blink app of unexpected events in your home, and allows you monitor your camera in real time.
                a(:href='codeLinks.water.url' class="tds-small" target='_blank') View 
                  strong Water Leak Kit 
                  | On Amazon.com 
                  small (opens in new window)
                ul(class="tds-small")
                  li SmartThings Hub
                  li SmartThings Water Sensor (2)
                  li Blink Indoor Camera
        h3 Use your promotion code on one of the above kits to save $25 off the Amazon.com price.
        br
        PromoCode(
          :promoCode='promoCode'
          promoType='smartkitv3'
          buttonText='Get your code for a discounted Smart Home Kit'
          :links='codeLinks'
        )

        small 
          i This program expires on June 30, 2020. Limit one per qualifying policy. Offer not transferable. Offer code must be redeemed within 30 days of receipt of code. Only one code will be given per policy.  Offer not available for policyholders that took advantage of prior Smart Home Device Kit offers.

        br
        AmazonPromoInformation

        br
        AmazonFaq
</template>

<script>
import PromoCode from './PromoCode'
import EchoDot from './EchoDot'
import AmazonPromoInformation from './AmazonPromoInformation'
import AmazonFaq from './AmazonFaq'

export default {
  components: {
    EchoDot,
    PromoCode,
    AmazonPromoInformation,
    AmazonFaq
  },
  props: {
    promoCode: {
      type: String,
      default: ''
    },
    promoType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageMap: {
        smarthomeguard: '/assets/img/smart-home-kit-guard-800x600.png',
        smarthomecamera: '/assets/img/smart-home-kit-camera-800x600.png',
        smarthomewater: '/assets/img/smart-home-kit-water-800x600.png',
        amazonlogo: '/assets/img/amazon-logo-333x100.png'
      },
      codeLinks: {
        guard: {
          url:
            'https://www.amazon.com/Travelers-Insurance-Smart-Home-Bundle/dp/B082G3R5TH',
          text: 'Get the Alexa Guard Kit on Amazon.com'
        },
        camera: {
          url:
            'https://www.amazon.com/Travelers-Insurance-Smart-Home-Bundle/dp/B07ZGC8NCL',
          text: 'Get the Camera Kit on Amazon.com'
        },
        water: {
          url:
            'https://www.amazon.com/Travelers-Insurance-Smart-Home-Bundle/dp/B07ZV65WCM',
          text: 'Get the Water Leak Kit on Amazon.com'
        }
      },
      alexaGuardOfficialLink: 'http://amazon.com/alexaguard'
    }
  }
}
</script>
