const authModule = api => {
  const state = {
    authToken: window.localStorage.getItem('authToken')
  }

  const getters = {
    authToken: state => state.authToken,
    isPolicyValidated: state => !!state.authToken,
  }

  const mutations = {
    authToken(state, authToken) {
      state.authToken = authToken
      window.localStorage.setItem('authToken', authToken);
    }
  }

  const actions = {
    verifyPolicy({ commit }, { policyNumber, zipCode }) {
      commit('authToken', null)
      const policyNumberFormatted = policyNumber.replace(/-/g, '')
      return api.verifyPolicy(policyNumberFormatted, zipCode)
        .then(response => {
          commit('authToken', response.token)
        })
        .catch(err => {
          commit('authToken', null)
          throw err
        })
    }
  }

  return {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
  }
}

export default {
  module: authModule
}
