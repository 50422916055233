<template lang="pug">
  div(class="tds-card tds-card--medium tds-card--level-5")
    div(class="tds-card__body")
      h2(class="tds-h2--article") Flo by Moen Smart Water Detector

      p 
        | Water leaks are a common and costly cause of damage to homes. 
        | And, it can often be difficult to notice one until a major incident has occurred. 
        | Travelers wants to help you get alerted if a leak is detected with a 
        |
        a(href="https://meetflo.com/pages/partner-travelers" target="_blank") Smart Water Detector
        |
        | from Flo by Moen (at no cost to you, including free shipping). 
        | Additional terms apply.*
      
      p In addition, we will apply a discount** to your home or condo insurance policy when you receive and activate your Smart Water Detector!
           
      .images
        img.responsive-offer-imgs(:src="imageMap.display" alt='Flo product')

      QuestionMarkAlert(level="success")
        h4
          | How is your Smart Water Device data used? 
        p
          | So what's in it for us? 
          | Besides helping you identify a leak before it becomes a major catastrophe, Travelers also wants to learn more about the ways these smart water devices can help our customers. 
          | By taking part in this offer, Flo by Moen will share device activation status and other device information with us. 
          | Information about other devices connected to your Flo by Moen account may be included. 
          | We will only use this information to verify activation status to apply any available discount to your policy** and to analyze the effectiveness and usage of smart water devices generally with no other impact to an individual policy or claim. 
          | Please read Flo by Moen's 
          |
          a(href="https://support.meetflo.com/hc/en-us/articles/230425728-Privacy-Statement" target="_blank") Privacy Statement
          |
          | and 
          |
          a(href="https://support.meetflo.com/hc/en-us/articles/230089687-Terms-of-Service" target="_blank") Terms of Service
          |
          span . 

      PromoCode.promo-code(
        :promoCode="promoCode"
        promoType="flodetector"
        buttonText="GET YOUR CODE FOR A SMART WATER DETECTOR"
        :links='codeLinks'
      )

      small
        p
          i * Quantities are limited. This program expires on May 31, 2022, or when supplies are exhausted. Limit one per qualifying policy. Offer not transferable. Offer code must be redeemed within 30 days of receipt of code. Travelers may amend the terms of this program at any time.
        p
          i 
            | ** Protective device discount on qualifying policies available with use of the Smart Water Detector. 
            | Amount of discount will vary by location, policy type and current discounts. 
            | As part of this offer, Flo by Moen will share activation and other device information with Travelers. 
            | Information about other devices connected to your Flo by Moen account may be included. 
            | In the event that we are able to confirm activation of a policyholder’s device(s) based upon the information provided to us by Flo by Moen, we will automatically apply any available discount to the policy as of the activation date, and customer consents to allow Travelers to apply these discounts to their policy.  
            | Please allow 2-4 weeks after activation of Smart Water Detector for discount(s) to be applied. 
            | There may be situations where Travelers is unable to verify a device activation. 
            | If your discount has not been applied within 30 days of activating device, please contact your insurance representative to have the discount applied to your policy. 
            | Discount will be applied as of the activation date.
      
      MeetfloPromoInformation

      br
      MeetfloFaq
</template>

<script>
import PromoCode from './PromoCode.vue'
import MeetfloPromoInformation from './MeetfloPromoInformation'
import MeetfloFaq from './MeetfloFaq'
import QuestionMarkAlert from '../tds/QuestionMarkAlert'

export default {
  components: {
    PromoCode,
    MeetfloPromoInformation,
    MeetfloFaq,
    QuestionMarkAlert
  },
  props: {
    promoCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      claimedCode: '',
      imageMap: {
        display: '/assets/img/flo-smart-detector.jpg'
      },
      codeLinks: {
        flodetector: {
          url: 'https://meetflo.com/pages/partner-travelers',
          text: 'Go to meetflo.com to claim your Smart Water Detector'
        }
      }
    }
  }
}
</script>
<style scoped>
  .images {
    margin-bottom: 1rem;
  }

  .promo-code {
    margin-bottom: 1rem;
  }

</style>