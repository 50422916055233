import Layout from '../Layout.vue'
import ValidatePolicy from '../components/ValidatePolicy.vue'

const landingRoutes = [
  {
    path: '/',
    component: Layout,
    props: { button: 'menu' },
    children: [
      { path: '/', name: 'validatePolicy', component: ValidatePolicy }
    ]
  }
]

export default landingRoutes
