const mixin = initialState => {
    return {
      data () {
        return {
          loading: initialState
        }
      },
      methods: {
        loadingDone () {
          this.loading = false
        }
      }
    }
  }
  
  export default mixin
  