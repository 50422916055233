<template lang="pug">
  div(class="tds-card tds-card--medium tds-card--level-5")
     div(class="tds-card__body")
        h2(class="tds-h2--article") FAQ
      
        h4 Why am I not eligible at this time?
        p Either no promotions are active, or you have used up earlier promotions.

        h4 Are you sure I'm not eligible?
        p If you believe you meet the eligibility outlined 
          a(href="http://www.travelers.com/additionalbenefits" target="_blank") here
          | , please email our support team at 
          a(:href="supportEmailLink") {{supportEmail}}
          | .  Be sure to include your name and policy number in the email.

</template>

<script>
import { mapGetters } from 'vuex'
import { path } from 'ramda'

export default {
  data() {
    return {
      supportEmail: 'PIPromoSupport@travelers.com'
    }
  },
  computed: {
    ...mapGetters('policy', ['policy']),
    supportEmailLink() {
      const policyNumber = this.policy.policyNumber ? this.policy.policyNumber : 'N/A'
      const zipCode = path(['customer', 'address', 'zipCode'], this.policy) ? path(['customer', 'address', 'zipCode'], this.policy).substring(0, 5) : 'N/A'
      const body = encodeURIComponent(
        `Travelers Promotion Support Team,
    Please verify my eligibility for any promotions.
        
    Pol #:  ${policyNumber}
    Zip Code: ${zipCode}
    `
      )
      const subject = encodeURIComponent(
        `Additional Benefits Policy Eligibility`
      )
      return `mailto:${this.supportEmail}?subject=${subject}&body=${body}`
    }
  }
}
</script>
