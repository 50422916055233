<template lang="pug">
  div(class="tds-col-lg-12")
    main(id="maincontent" class="tds-container")
      h1 You are not eligible for any additional benefits at this time.
      FaqNothing

</template>

<script>
import FaqNothing from './Faq.not.eligible'

export default {
  components: {
    FaqNothing
  },
  layout: 'promotions',
  middleware: ['policy', 'promo']
}
</script>

<style scoped lang="scss"></style>
