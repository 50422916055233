const policyModule = api => {
  const state = {
    assignedPromotions: {},
    eligiblePromotions: {},
    policy: {}
  }

  const getters = {
    assignedPromotions: state => state.assignedPromotions,
    eligiblePromotions: state => state.eligiblePromotions,
    policy: state => state.policy
  }

  const mutations = {
    assignedPromotions (state, assignedPromos) {
      state.assignedPromotions = assignedPromos
    },
    eligiblePromotions (state, eligiblePromos) {
      state.eligiblePromotions = eligiblePromos
    },
    policy (state, policy) {
      state.policy = policy
    }
  }

  const actions = {
    loadPolicy ({ commit }) {
      return api.getPolicy()
        .then(result => {
          commit('policy', result.policy)
          commit('eligiblePromotions', result.eligiblePromos)
          commit('assignedPromotions', result.assignedPromos)          
        })
    }
  }

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
}

export default {
  module: policyModule
}
