import Vue from 'vue'
import Router from 'vue-router'
import landingRoutes from './landing'
import mainRoutes from './main'
import error from './error'
import authPages from './authPages'
import store from '../store'

Vue.use(Router)

const routes = [
  ...landingRoutes,
  ...mainRoutes,
  ...error
]

const router = new Router({
  base: '/',
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      window.location.href = to.hash
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Auth guard
router.beforeEach((to, from, next) => {
  const toName = to.name

  if (toName.startsWith('/error')) {
    return next()
  }

  if (!store.getters['auth/isPolicyValidated'] && authPages.noAuthNeeded.indexOf(toName) < 0) {
    next(authPages.authPath)
  }

  next()
})

export default router
