<template lang="pug">
  div(class="tds-card tds-card--medium tds-card--level-5")
    div(class="tds-card__body")
      h2(class="tds-h2--article") FREE Echo Dot
      img.partner-logo(:src="imageMap['amazonlogo']" alt='Amazon logo')
      a(:href="codeLinks.echoDot.url" target="_blank")
        img.responsive-offer-imgs(:src="imageMap['echo']")
      p(class="tds-small") Help manage your policy and your home. Get a FREE 
        a(:href="codeLinks.echoDot.url" class="tds-small" target="_blank")
          | Amazon Echo Dot 
        |  and enable the 
        a(href="https://www.amazon.com/Travelers/dp/B07J2S9P8D" target="_blank") 
          | Travelers
        |  and 
        a(href="https://www.amazon.com/gp/product/B07SB5NHKR" target="_blank")
          | Travelers Home Central
        |  skills for Amazon Alexa.

      PromoCode(
        :promoCode="promoCode"
        promoType="echodot"
        buttonText="Get your code for a free echo dot"
        :links='codeLinks'
      )

      small 
        i Quantities are limited. This program expires on September 30, 2020, or when supplies are exhausted. Limit one per qualifying policy. Offer not transferable. Offer code must be redeemed within 30 days of receipt of code. Only one code will be given per policy. The Echo Dot is provided by Travelers, Amazon is not a sponsor of this offer.
      
      br
      AmazonPromoInformation

      br
      AmazonFaq
</template>

<script>
import PromoCode from './PromoCode.vue'
import AmazonPromoInformation from './AmazonPromoInformation'
import AmazonFaq from './AmazonFaq'

export default {
  components: {
    PromoCode,
    AmazonPromoInformation,
    AmazonFaq
  },
  props: {
    promoCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      claimedCode: '',
      imageMap: {
        echo: '/assets/img/echo-dot-340x480.png',
        amazonlogo: '/assets/img/amazon-logo-333x100.png'
      },
      codeLinks: {
        echoDot: {
          url: process.env.echoUrl,
          text: 'Get the Echo Dot on Amazon.com'
        }
      }
    }
  }
}
</script>
