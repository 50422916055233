<template lang='pug'>
  .register-web
    Header
    div.tds-body(data-template="landing")
      div.tds-container
        div
          slot
    footer(class="tds-global-footer")
      div(class="tds-global-footer__wrapper")
       div(class="tds-global-footer__identity")
        Icon(name="trv-logo-2color-small" styleClass="tds-global-footer__logo")
        p(class="tds-global-footer__copy") © <span id="copyright-year">2020</span> The Travelers Indemnity Company. All rights reserved.     
   
</template>


<script>
import Header from '../../Header'
import Icon from '../tds/Icon'
import svg4everybody from 'svg4everybody'
export default {
   components: {
    Header,
    Icon
  },
  mounted() {
    svg4everybody()
  } 
}
</script>