<template lang="pug">
  .promotion-code
    .button-container(v-if="!promotionCodeToDisplay")
      Button.promo-button(@click="getPromoCode" :loading="claimingLoad") {{ buttonText }}
      div(v-for="item in Object.keys(links)") 
        p
          a(:href="links[item].url" target="_blank") {{ links[item].text }}
    .button-container(v-else-if="errorObtainingCode")
      Button.promo-button(disabled) {{ promoError }}
      div(v-for="item in Object.keys(links)") 
        p
          a(:href="links[item].url" target="_blank") {{ links[item].text }}
    .promo-code-container(v-else)
      p
        strong YOUR CODE 
      p.red-text {{ promotionCodeToDisplay }} 
      p
        CopyToClipboard(:promoCode="promotionCodeToDisplay")
      div(v-for="item in Object.keys(links)") 
        p
          a(:href="links[item].url" target="_blank") {{ links[item].text }}
</template>

<script>
import Button from '../../components/tds/Button'
import { find, propEq, reject } from 'ramda'
import { mapGetters } from 'vuex'
import CopyToClipboard from '../CopyToClipboard.vue'

const currentPromo = (type, promotions) => {
  return find(propEq('type', type), promotions)
}

const addCodeToPromo = (promo, code) => {
  return { ...promo, ...{ code } }
}

const replaceUpdatedPromo = (promos, promo) => {
  const excludedPromos = reject(propEq('type', promo.type), promos)
  const updatedPromos = [...excludedPromos, promo]

  return updatedPromos
}

export default {
  components: { Button, CopyToClipboard },
  props: {
    promoCode: {
      type: String,
      default: ''
    },
    promoType: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'GET CODE'
    },
    links: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      claimedCode: '',
      claimingLoad: false,
      promoError: ''
    }
  },
  computed: {
    promotionCodeToDisplay() {
      return !this.promoError && this.promoCode !== 'none' && this.promoCode
        ? this.promoCode
        : this.claimedCode
    },
    errorObtainingCode() {
      return this.promoError
    }
  },
  methods: {
    getPromoCode() {
      this.claimingLoad = true
      this.$emit('promoError', '')

      return this.$api
        .requestCode(this.promoType)
        .then(promoCodeInfo => {
          if (promoCodeInfo.errorMessage) {
            let msg =
              'Due to the popularity of this promotion, we have run out of codes but are working to get more as fast as possible.  Travelers will send you an email when more codes become available so that you can come back and get your code then. We appreciate your patience.'
            this.promoError = msg
            this.$emit('promoError', msg)
          } else {
            this.claimingLoad = false
            this.claimedCode = promoCodeInfo.promoCode
            this.$emit('promoCodeClaimed', this.promoType)
          }
        })
        .catch(err => {
          this.claimingLoad = false
          const errorMessage = 'There was an error receiving your promotion code for your policy.'
          this.promoError = errorMessage
          this.$emit('promoError', errorMessage)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.promo-code-container {
  background-color: #fcedba;
  padding: 8px 16px;
  border: 2px solid #f5c318;
  border-radius: 4px;
}

.promotion-code p {
  margin: 0;
}

.promo-button {
  margin-bottom: .5rem;
  white-space: normal;
}
</style>
