<template lang="pug">
  div.tds-field
    label(class="tds-field__label" :for="id" ) {{label}}
    input(class="tds-field__input"
        :type="type"
        @input="$emit('input', $event.target.value)"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :id="id"
        data-enhanced="true"
        :aria-required="required"
        v-mask="mask")
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  directives: {
    mask
  },
  props: {
    mask: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: -1
    },
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      validator: str =>
        ['tel', 'email', 'search', 'password', 'url', 'text'].indexOf(str) !==
        -1,
      default: 'text'
    }
  }
}
</script>
