
import { prop } from 'ramda'
import axios from 'axios'
import store from '../store'
const basePath = '/api'
const getData = prop('data')

const authToken = () => {
  return store.getters['auth/authToken']
}

const accessTokenHeader = () => {
  return { 'x-access-token': authToken() }
}

const authHeaders = () => {
  return {
    headers: {
      ...accessTokenHeader()
    }
  }
}

const methods = () => {
  return {
    getPolicy() {
      return axios.get(`${basePath}/policies?token=${authToken()}`, authHeaders())
        .then(getData)
    },
    requestCode(promoType) {
      return axios.post(`${basePath}/promo?token=${authToken()}`, { promoType }, authHeaders())
        .then(getData)
    },
    decryptToken(policyToken) {
      return axios.post(`${basePath}/policies/token`, { token: policyToken })
        .then(getData)
    },
    verifyPolicy(policyNumber, zipCode) {
      return axios.post(`${basePath}/policies`, { policyNumber, zipCode })
        .then(getData)
    }
  }
}

export default methods
