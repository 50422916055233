
import Vue from 'vue'
import Vuex from 'vuex'
import policy from './modules/policy'
import auth from './modules/auth'
import apif from '../services/api'
import VuexPersistence from 'vuex-persist'

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['policy']
})

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const api = apif()

const store = new Vuex.Store({
  modules: {
    auth: auth.module(api),
    policy: policy.module(api)
  },
  strict: debug,
  plugins: [vuexSession.plugin]
})

export default store

