<template lang="pug">
   div(class="tds-col-lg-12")
      main(id="maincontent" class="tds-container")
        template(v-if="loading")
          div(class='tds-row' style='text-align:center;')
            div(class='tds-col-md')
              Spinner(name="arrow-cycle-1-24")
        template(v-else-if="eligiblePromotionsCount === 0 && assignedPromotionsCount === 0")
              NoPromotions()
        template(v-else)

          div(class="tds-container")
            Alert(level="error" :message="alertError" v-if="alertError")
            h1(class="tds-h1--article") You are eligible for the following {{ benefitText }} from Travelers

            .tab-list(v-if="eligiblePromotionsTypes.count + assignedPromotionsTypes.count > 1")
              template(v-for="promo in eligiblePromotionsTypes")
                p.tab(@click="tabClick(promo)" :class="getTabClass(promo)") {{ promoTypeNameMap[promo] }}
              template(v-for="promo in assignedPromotionsTypes")
                p.tab(@click="tabClick(promo)" :class="getTabClass(promo)") {{ promoTypeNameMap[promo] }}

            SmartKitsV3(v-if="selectedPromo === 'smartkitv3'" :promoCode="getPromoCode('smartkitv3')")
            EchoDot(v-else-if="selectedPromo === 'echodot'" :promoCode="getPromoCode('echodot')")
            Flodetector(v-else-if="selectedPromo === 'flodetector'" :promoCode="getPromoCode('flodetector')")
</template>

<script>
import SmartKitsV3 from './SmartKitsV3'
import EchoDot from './EchoDot'
import NoPromotions from './NoPromotions'
import Flodetector from './Flodetector'

import PromoCode from './PromoCode'
import { mapActions, mapGetters } from 'vuex'
import Alert from '../tds/Alert'
import Spinner from '../tds/Spinner'
import { find, flatten, path } from 'ramda'
import loading from '../mixins/loading'
import errorHandler from '../../helpers/errorRedirect'

export default {
  components: {
    Alert,
    Spinner,
    PromoCode,
    EchoDot,
    SmartKitsV3,
    NoPromotions,
    Flodetector
  },
  layout: 'promotions',
  data: () => {
    return {
      alertError: '',
      showSmartInfo: false,
      selectedPromo: '',
      promoTypeNameMap: {
        flodetector: 'Flo Detector',
        echodot: 'Echo Dot',
        smartkitv3: 'SmartKit V3'
      }
    }
  },
  mixins: [loading(true)],
  computed: {
    ...mapGetters('policy', ['eligiblePromotions', 'assignedPromotions']),
    ...mapGetters('auth', ['isPolicyValidated']),
    eligiblePromotionsCount() {
        return  this.eligiblePromotions.length
    },
    assignedPromotionsCount() {
        return  this.assignedPromotions.length
    },
    eligiblePromotionsTypes() {
      return this.eligiblePromotions.length > 0
        ? flatten(this.eligiblePromotions.map(ep => ep.promoType))
        : []
    },
    assignedPromotionsTypes() {
       return this.assignedPromotions.map(ap => ap.type)
    },
    offerCount() {
      return this.eligiblePromotions.length + this.assignedPromotions.length
    },
    benefitText() {
      return this.offerCount === 1
        ? 'Additional Benefit'
        : `${this.offerCount} Additional Benefits`
    }
  },
  created () {
    if (!this.isPolicyValidated) {
      return this.$router.push('/')
    }
    return this.loadPolicy()
      .then(() => {
        const eligiblePromoTypes = this.eligiblePromotionsTypes
        if (eligiblePromoTypes.length > 0) {
          this.selectedPromo = this.eligiblePromotionsTypes[0]
        } else {
          const assignedPromoTypes = this.assignedPromotionsTypes
          if (assignedPromoTypes.length > 0) {
            this.selectedPromo = this.assignedPromotionsTypes[0]
          }
        }
      })
      .then(this.loadingDone, errorHandler(this.$router))
      .catch(err => {
        redirect('/')
      })
  },
  methods: {
    ...mapActions('policy', ['loadPolicy']),
    promoCodeError(errorMessage) {
      this.alertError = errorMessage
    },
    findKit(kit, promos) {
      return find(promotion => kit.indexOf(promotion.type) > -1, promos || [])
    },
    displayPromotion(promoType) {
      if(this.eligiblePromotions.length > 0) {
        return this.eligiblePromotionsTypes.some(ept => ept === promoType) 
          || this.assignedPromotionsTypes.some(apt => apt === promoType)
      }
      return this.assignedPromotionsTypes.length > 0 
        && this.assignedPromotionsTypes.some(apt => apt === promoType)
    },
    getPromoCode(promoType) {
      const assignedPromo = find(
        ap => ap.type === promoType,
        this.assignedPromotions
      )
      return path(['code'], assignedPromo) || 'none'
    },
    tabClick(promo) {
      this.selectedPromo = promo
    },
    getTabClass(promo) {
      return promo === this.selectedPromo ? 'tab-selected tab-selected:hover' : 'tab:hover'
    }
  }
}
</script>
<style scoped>
  .tab {
    display: inline;
    margin-right: 1rem;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .tab-selected {
    border-bottom: .2rem solid #af1214;
  }

  .tab:hover {
    cursor: pointer;
    border-bottom: .2rem solid #364253;
  }

  .tab:hover.tab-selected  {
    cursor: default;
    border-bottom: .2rem solid #af1214;
  }

  .tab-list {
    margin-bottom: 1rem;
  }
</style>