<template lang="pug">
  button(
    :class="buttonClass"
    @click="click"
    :aria-disabled="disabled"
    :aria-busy="loading ? 'true' : 'false'")
    slot
</template>

<script>
export default {
  props: {
    buttonClass: {
      type: String,
      default: 'tds-button--primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click(e) {
      return this.$emit('click', e)
    }
  }
}
</script>
